import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Single post view (/:slug)
*
* This file renders a single post and loads all the content.
*
*/
const Post = ({ data, location }) => {
    const post = data.ghostPost

    return (
        <>
            <MetaData data={data} location={location} type="article" />
            <Helmet>
                <style type="text/css">{`${post.codeinjection_styles}`}</style>
            </Helmet>
            <Layout>
                <article className="content">
                    <header className="post-full-header">
                        <section className="post-full-meta">
                            <time
                                className="post-full-meta-date"
                                dateTime={post.published_at}
                            >
                                {post.published_at_pretty}
                            </time>
                            {post.tags?.length > 0 && (
                                <>
                                    <span className="date-divider">/</span>
                                    <a href={`/tag/${post.tags[0].slug}`}>
                                        {post.tags[0].name}
                                    </a>
                                </>
                            )}
                        </section>
                        <h1 className="post-full-title">{post.title}</h1>
                    </header>

                    {post.feature_image ? (
                        <figure
                            className="post-full-image"
                            style={{
                                backgroundImage: `url(${post.feature_image})`,
                            }}
                        ></figure>
                    ) : null}
                    <section className="post-full-content">
                        <div
                            className="kg-card-markdown"
                            dangerouslySetInnerHTML={{ __html: post.html }}
                        ></div>
                    </section>
                </article>
            </Layout>
        </>
    )
}

Post.propTypes = {
    data: PropTypes.shape({
        ghostPost: PropTypes.shape({
            codeinjection_styles: PropTypes.object,
            title: PropTypes.string.isRequired,
            html: PropTypes.string.isRequired,
            feature_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
    }
`
